<template>
  <div>
    <b-overlay
      :show="is_loading"
      spinner-variant="primary"
      variant="transparent"
      style="flex-wrap: wrap; position: unset !important"
    >
      <b-card-body
        v-if="Offers.items.length === 0 && !is_loading"
        class="d-flex flex-column p-4"
      >
        <div class="d-flex flex-column justify-content-center">
          <div class="nodata-icon ml-auto mr-auto"></div>
          <p class="text-muted mt-1 ml-auto mr-auto text-center">
            Нет предложений
          </p>
        </div>
      </b-card-body>

      <div class="d-grid-cards-catalog" v-else-if="!is_loading">
        <offer-card-view
          v-for="(offer, index) in Offers.items"
          :key="index"
          :item="offer"
        />
    </div>
    </b-overlay>

    <!-- <info-horizontal-card-view /> -->

    <div class="mt-2 btn-more-footer" v-if="Offers.has_more_pages && !is_loading">
      <center>
        <b-button
          v-if="!is_additional_loading"
          @click="loadMore"
          variant="primary-dark"
        >
          Показать еще
        </b-button>
        <b-button v-else variant="primary-dark" disabled>
          <b-spinner small type="grow"></b-spinner>
          Загрузка...
        </b-button>
      </center>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

import VueSlider from "vue-slider-component";
import vSelect from "vue-select";

import OfferCardView from "@/components/OfferCard";
import StretchOfferCardView from "@/components/StretchOfferCard";
import InfoHorizontalCardView from "@/components/InfoHorizontalCard";

import Offers from "@/modules/offers/";

export default {
  data() {
    return {
      Offers,
      is_loading: true,
      is_additional_loading: false,
      filter: {
        category: null,
        company: null,
        action: null,
      },
    };
  },
  props: {},
  methods: {
    multiWidth(items) {
      if (items.length == 1) return { width: "100%" };
      else if (items.length == 2) return { width: "49%" };
      else return { width: "32%" };
    },
    loadMore() {
      this.is_additional_loading = true;
      Offers.load({ category: this.$route.query.category, page: ++Offers.current_page }, () => {
        this.is_additional_loading = false;
      });
    },
  },
  components: {
    VueSlider,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    OfferCardView,
    StretchOfferCardView,
    InfoHorizontalCardView,
  },

  computed: {},

  watch: {
    "$route.query": function () {
      console.log(this.$route.query.category);
      this.is_loading = true;
      Offers.list(true, { category: this.$route.query.category }, () => {
        this.is_loading = false;
      });
    },
  },
  mounted() {
    console.log(this.$route.query.category);
    Offers.list(true, { category: this.$route.query.category }, () => {
      this.is_loading = false;
    });
  },
};
</script>


<style lang="scss">
</style>
